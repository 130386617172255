export default [
  {
    name: 'Фортум',
    logo: require('@/assets/partners/1-организатор-Лидеры-изменений.png'),
    href: 'https://changeleaders.ru/'
  },
  {
    name: 'ВТБ',
    logo: require('@/assets/partners/2-спонсоры-01-Газпромбанк.png'),
    href: 'https://www.gazprombank.ru/'
  },
  {
    name: 'Сбер',
    logo: require('@/assets/partners/2-спонсоры-02-ингосстрах.png'),
    href: 'https://www.ingos.ru/'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/2-спонсоры-03-некстепик.png'),
    href: 'https://nextepic.ru/'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/2-спонсоры-04-тим-сторм.png'),
    href: 'https://teamstorm.io/'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/2-спонсоры-05-мтс-линк.png'),
    href: 'https://mts-link.ru/'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/3-техн-партнер-ВТБ.png.png'),
    href: 'https://www.vtb.ru/'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/3-техн-партнер-ALMA-MEDIA.jpg'),
    href: 'https://almamedia.pro/'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/4-сми-1-рбк-про.png'),
    href: 'https://pro.rbc.ru/'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/4-сми-2-комньюс.png'),
    href: 'https://www.comnews.ru/'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/4-сми-3-ancor.png'),
    href: 'https://ancor.ru/'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/4-сми-клерк.png'),
    href: 'https://www.klerk.ru/'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/4-сми-смарт-рэнкин.png'),
    href: 'https://smartranking.ru/ru/'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-13.png'),
    href: 'https://globaltechforum.ru/?utm_source=partner&utm_medium=enterprise'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-06.png'),
    href: 'https://kazandigitalweek.com/ru/site'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-07.png'),
    href: 'https://skolkovo2024.mergeconf.ru/#tickets'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-05.png'),
    href: 'https://productsense.io/'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-20.png'),
    href: 'https://techweek.moscow/?utm_source=partner&utm_medium=enterpriseagilerussia&utm_campaign=inf'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-28.png'),
    href: 'https://www.agroros.ru/'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-24.png'),
    href: 'https://anodigital.ru/'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-04.png'),
    href: 'https://aspro.cloud/?utm_source=enterprise-agile&utm_medium=pr&utm_campaign=cloud_cold_razmechenie-logo_191024_logo&utm_term=event'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-26.png'),
    href: 'https://astral.ru/'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-25.png'),
    href: 'https://giprosvjaz.by/'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-23.png'),
    href: 'https://www.gnivc.ru/'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-14.png'),
    href: 'https://gorodzovet.ru/moskva/'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-18.png'),
    href: 'https://ih.rosatom.ru/'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-03.png'),
    href: 'https://mb.community/?utm_medium=referal&utm_campaign=agile_conference'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-27.png'),
    href: 'https://mts.ru/'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-15.png'),
    href: 'https://www.pmregatta.ru/'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-12.png'),
    href: 'https://www.rosbank.ru/'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-02.png'),
    href: 'https://www.company.rt.ru/'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-01.png'),
    href: 'https://tedo.ru/technology'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-31.png'),
    href: 'https://forating.ru/'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-19.png'),
    href: 'https://www.festpir.ru/'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-22.png'),
    href: 'https://eksmo.ru/podborki/biznes/'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-10.png'),
    href: 'https://all-events.ru/'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-08.png'),
    href: 'https://expomap.ru/?utm_source=enterprise-agile-russia'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-29.png'),
    href: 'https://fastlife.su/?utm_source=forum'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-09.png'),
    href: 'https://gdejob.com/'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-21.png'),
    href: 'https://t.me/hrafisha'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-33.png'),
    href: 'https://vsezaimyonline.ru/'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-17.png'),
    href: 'https://ict2go.ru/'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-32.png'),
    href: 'https://it-event-hub.ru/'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-30.png'),
    href: 'https://sboard.online/?utm_source=ear_conf'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-16.png'),
    href: 'https://digital-spectr.ru/?utm_source=agileconf'
  },
  {
    name: 'Дикси',
    logo: require('@/assets/partners/5-инф-11.png'),
    href: 'https://workspace.ru/'
  }
]
